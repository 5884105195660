import React from 'react'
import content from 'assets/contents/history-es'
import mainImage from 'assets/img/func-vector-3.png'
import { Container } from 'reactstrap'

export default function SectionOne() {
  return (
    <div id='section-one' className='text-bg-dark p-5 pt-0'>
      <Container>
        <div className='row p-5 pt-0'>
          <div className='col col-sm-7 justify-content-center p-5'>
            <h2>{content.purpose.title}</h2>
            {content.purpose.content.map((c,k) => {
              return <h4 key={k}>{c}</h4>
            })}
          </div>
          <div className='col col-sm-5 justify-content-center pt-5'>
              <img
                alt="bzr-demo-collage"
                src={mainImage}
              ></img>
          </div>
        </div>
      </Container>
    </div>
  );
}



