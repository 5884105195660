import React from 'react'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import Header from './Header'
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'

const Home = (props) => {


  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return(
      <>
        <Navbar />
        <div className="wrapper">
          <Header />
          <div className="main">
            <div id="back-to-top-anchor"></div>
            <SectionOne />
            <SectionTwo />
          </div>
{/*          
          <SectionTwo />
          <SectionIntegrations />
          <SectionThree /> */}
          <Footer />
        {/*  <Fade in={show}>
            <Button className="btn-icon btn-round" color="info" type="button" onClick={handleBackToTop}>
              <i className="now-ui-icons ui-2_favourite-28"></i>
            </Button>
          </Fade> */}
        </div>
      </>
  )
}

export default Home