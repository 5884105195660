import React from 'react';
import { useMediaQuery } from 'react-responsive'
import content from 'assets/contents/content-es'
import func1 from 'assets/img/facturacion.gif'
import func2 from 'assets/img/timeline.png'
import func3 from 'assets/img/multi-currency.png'
import func4 from 'assets/img/price-list.png'
import func5 from 'assets/img/func-5.png'
import func6 from 'assets/img/product-inventory.png'
import func7 from 'assets/img/func-7.png'
import func8 from 'assets/img/func-8.png'
import func9 from 'assets/img/func-9.png'
/* import funcVector1 from 'assets/img/func-vector-3-1.png'
import funcVector2 from 'assets/img/func-vector-2.png'
import funcVector3 from 'assets/img/func-vector-3-2.png'
import curve003 from 'assets/img/curve-003.png' */

// reactstrap components
import { Container, Row, Col, Modal, ModalHeader, ModalBody, Card, CardBody, CardTitle, CardText } from "reactstrap";

const imgArray = [func1, func2, func3, func4, func5, func6, func7, func8, func9]

export default function CenteredGrid(props) {
  const [openDemo,setOpenDemo] = React.useState(false)
  const handleOpenDemo = (s) => setOpenDemo(s)
  const handleCloseDemo = () => setOpenDemo(false)

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  
  return (
    <div id='section-two-functionalities' className="text-bg-light" style={{paddingTop: 100}}>
      
      <Container>
        <div className='w-75'>
          <h2 className="text-start text-dark">{content.bizor_s_specs.title}</h2>
          <h4 className='text-info-emphasis fw-bold pb-5'>{content.bizor_s_specs.description}</h4>
        </div>
        {/* BLOQUE 2 */}
        <Row xs='1' sm='2' lg='3'>
        {content.bizor_s_specs.functionalities.map((f,k) => 
          <Col className={isDesktopOrLaptop ? 'p-5' : 'p-3'}>
            <Card key={k}  >
              {imgArray[k] &&  
                <img
                  alt={"func-block1-img-" + k }
                  src={imgArray[k]}
                  onClick={() => handleOpenDemo(imgArray[k])}
                  style={{cursor: 'pointer'}}
                  />
              }
              <CardBody>
                <CardTitle tag="h5">{f.title}</CardTitle>
                {/* <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Card subtitle
                </CardSubtitle> */}
                <CardText>{f.description}</CardText>
              </CardBody>
            </Card>
          </Col>
          )}
        </Row>
      </Container>
      <Modal isOpen={!!openDemo} toggle={handleCloseDemo} size="xl" style={{padding: 16}}>
        <ModalHeader toggle={handleCloseDemo}>cerrar</ModalHeader>
        <ModalBody >
          <img
            alt={"fdialog-img"}
            className=""
            src={openDemo}
          ></img>
          
        </ModalBody>
      </Modal>
    </div>

  );
}



