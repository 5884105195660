import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import logo from 'assets/img/logo-white.png'
import logoB from 'assets/img/logo-blue.png'
import content from 'assets/contents/content-es'


export default function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState(props.navColor || "navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor(props.navColor || "navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={`fixed-top ${navbarColor}`} expand="lg" color="white">
        <Container className="d-flex justify-content-between align-items-center">
          <div className="navbar-translate d-flex align-items-center">
            <NavbarBrand
              href="/"
              id="navbar-brand"
            >
              <div className='d-flex align-items-center'>
                <img src={(!navbarColor || props.light || navbarColor.match(/pricing-navbar/)) ? logoB : logo} alt='bzr-logo' height={40} />
                &nbsp;&nbsp;
                <span style={{fontFamily: "Amaranth", fontSize: "2.2rem", textTransform: 'lowercase'}}>bizor</span>
              </div>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end pt-1"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink href="https://api.whatsapp.com/send?phone=584242552085">
                  <i className="bi bi-whatsapp" style={{fontSize: "1.4rem", marginRight: 10}}></i>
                  <p style={{paddingTop: 6}}>+58 (424) 2552085</p>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="bi bi-box" style={{fontSize: "1.2rem", marginRight: 10}}></i>
                  <p>Soluciones</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/Products/BizorS" tag={Link}>
                    <i className="now-ui-icons business_chart-pie-36 mr-1"></i>
                    Bizor S
                  </DropdownItem>
                  {/* <DropdownItem
                    href="https://demos.creative-tim.com/now-ui-kit-react/#/documentation/introduction?ref=nukr-index-navbar"
                    target="_blank"
                  >
                    <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                    Dedicadas
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="bi bi-cloud-plus" style={{fontSize: "1.4rem", marginRight: 10}}></i>
                  <p>Servicios</p>
                </DropdownToggle>
                <DropdownMenu>
                  {
                    content.services.map((service,key) => (
                      <DropdownItem key={key} to={service.url || "/Services"} tag={Link} className="p-3">
                        {service.icon && <i className={`${service.icon}`}></i>}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {service.caption}
                      </DropdownItem>
                    ))
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  href="/Products/BizorS/Pricing"
                >
                  {/* <i className="bi bi-cloud-plus" style={{fontSize: "1.4rem", marginRight: 10}}></i> */}
                  <p style={{paddingTop: 6}}>Precios</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/About"
                >
                  <p style={{paddingTop: 6}}>Conócenos</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <Button
                  color="primary"
                  href="https://app.bizor.io"
                  id="go-to-app"
                  target="_blank"
                  className="text-white"
                >
                <b>App</b>&nbsp;<i className="bi bi-box-arrow-right mr-1"></i>  
                </Button>
                <UncontrolledTooltip target="#go-to-app">
                  Ir a <b>Bizor S</b> App
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}