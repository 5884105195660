/*eslint-disable*/
import React from "react"
import { Container } from "reactstrap";
import aboutUsPng from 'assets/img/about_us_full.png'
import content from 'assets/contents/content-es'
import RequestDemoButton from 'components/RequestDemoButton'
import StartButton from 'components/StartButton'
//import { useMediaQuery } from 'react-responsive'
// core components

function Banner(props) {
  let pageBanner = React.createRef();

  React.useEffect(() => {
    
    if (pageBanner && window && window.innerWidth) {
      const updateScroll = () => {
        let windowScrollTop = window.scrollY / 3;
        pageBanner.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
  <div className="page-header">
        <div ref={pageBanner} >
          <div className="background-header-image-container">
            <img
              id="background-header-image"
              alt="bzr-demo-collage"
              src={aboutUsPng}
            ></img>
            <div className="clear-filter vw-100 vh-100 position-absolute top-0" filter-color="blue"></div>
          </div>
        </div>
        <Container style={{maxWidth: '100vw', paddingLeft: 0}}>
          <div className="header-block-caption" >
            <h5 className="text-start text-uppercase" style={{color: '#bbb'}}>{content.blockBanner.miniDesc}</h5>
            <h1 className="text-start"><strong>{content.blockBanner.claim}</strong></h1>
            <p className="text-start w-75 fs-4">{content.blockBanner.description}</p>
            <div className="header-block-actions mt-4">
              <RequestDemoButton style={{marginRight: 40}} />
              <StartButton />
            </div>
          </div>
        </Container>
      </div>

{/*       <Box className={classes.session1}>
        <Box className={classes.content} >
          <Typography className={classes.miniDesc}>{content.blockBanner.miniDesc}</Typography>
          <Typography component='h1' className={classes.mainTitle}>{content.blockBanner.claim}</Typography>
          <Typography component='h3' className={classes.claim}>{content.blockBanner.description}</Typography>
          <Box display='flex' justifyContent='space-around' mt={10}>
            <Box>
              <StartButton outlined />
            </Box>
            <Box width='45%' display={{xs: "none", sm: 'block'}} >
              <RequestDemoButton  />
            </Box>
          </Box>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center' flex={3}>
          <Parallax  y={[-30, 30]} tagOuter="figure">
            <iframe className={classes.demoVideo} src="https://www.youtube.com/embed/15B2d5fGEY4?si=Ly7NnqKc_sU-OnnO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </Parallax>
        </Box>
      </Box>
      <Box display={{xs: 'flex', sm: 'none'}} flexDirection='column' justifyContent='space-around' alignItems='center' mt={10}>
        <img
          alt="bzr-demo-collage"
          className={classes.demoCollage}
          src={bzrCollage}
        ></img>
        <Box display='flex' justifyContent='space-around' mt={10}>
          <RequestDemoButton  />
        </Box>
      </Box> */}
    </>
  );
}

export default Banner;