import React from 'react'
import Navbar from 'components/Navbar'
import Subscriptions from './Subscriptions'
import Faqs from 'views/Faq'
//import Sec from '../Home/SectionThree'
import Footer from 'components/Footer'


export default function PricingBizorS() {
    return(
        <div style={{scrollBehavior: 'smooth', whiteSpace: 'pre-wrap', backgroundColor: '#0dcaf02b'}}>
            <Navbar navColor='navbar-transparent pricing-navbar' />
            <Subscriptions />
            <Faqs />
            <Footer />
        </div>
    )
}