import React from 'react'
import content from 'assets/contents/history-es'
import { Container } from 'reactstrap'

export default function SectionTwo() {
  const missionHtml = () => {
    const newNode = document.createElement('span'); 
    newNode.innerHTML = content.mission.content
    return <h4 ref={(nodeElement) => {nodeElement && nodeElement.appendChild(newNode)}} />
  }
  const valuesTitle = () => {
    const newNode = document.createElement('span'); 
    newNode.innerHTML = content.values.title
    return <h2 className='text-end' ref={(nodeElement) => {nodeElement && nodeElement.appendChild(newNode)}} />
  }

  return (
    <div id='section-tow' className='p-5'>
      <Container>
        <div className='row p-5'>
          <div className='col col-sm-5 justify-content-center p-5'>
            <h2 className='text-end'>{content.mission.title}</h2>
          </div>
          <div className='col col-sm-7 justify-content-center pt-3'>
            {missionHtml()}
          </div>
          <div className='col col-sm-5 justify-content-center p-5'>
            {valuesTitle()}
          </div>
          <div className='col col-sm-7 justify-content-center p-3'>
            <ul>
              {content.values.content.map((v,k) => {
                return <li key={k}><h4>{v}</h4></li>
              })}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
}



