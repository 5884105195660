import React from 'react'
import {  } from 'react-router-dom'
import { Button } from 'reactstrap'
import content from 'assets/contents/content-es'

/* -- Calendly link widget begin -- */
/** Links and Scripts references were added to index.html */
export default function CalendlyButton(props) {
    
    /* 
    //   POP UP IMPLEMENTATITION FOR CALENDLY & HUBSPOT
    const [calendly,setCalendly] = React.useState()
    React.useEffect(() => {
        setCalendly(window.Calendly)
    },[]) */

    return(
        <Button
            className={`action-button-bg-white ${props.border ? " border" : ""}`}
            {...props}
            href="https://info.bizor.io/erp_ventas_gestion_pro_v0" 
            >
            {content.general.demoButton}
        </Button>
    )
}

/* 
Demo URLs

https://info.bizor.io/erp_ventas_gestion_pro_v0
https://meetings.hubspot.com/bizor

*/