import React, { useState } from 'react'
import StartButton from 'components/StartButton'
import RequestDemoButton from 'components/RequestDemoButton'
import content from 'assets/contents/content-es'

import {
  Container,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
export default function Faqs() {

  const [open, setOpen] = useState('');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div className='text-bg-light'>
      <Container >
        <h2>Preguntas Frecuentes</h2>
        <Accordion open={open} toggle={toggle} className='mb-5'>
        {content.faqs.generalFaqs.map((faq,key) => 
          <AccordionItem key={key}>
              <AccordionHeader targetId={key}>{faq.q}</AccordionHeader>
              <AccordionBody accordionId={key}>{faq.a}</AccordionBody>
          </AccordionItem>
        )}
        </Accordion>
        <h3>¿Aún tienes dudas?</h3>
        <h5>Agenda una demostración con uno de nuestros expertos </h5>
        <div className="d-flex justify-content-center mt-5" style={{paddingBottom: 100}}>
          <RequestDemoButton border={true} style={{marginRight: 80}} />
          <StartButton />
        </div>
      </Container>
    </div>
  );
}
