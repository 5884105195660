import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import BizorS from 'views/Products/BizorS'
import Services from 'views/Services'
import PricingBizorS from "views/Products/BizorS/Pricing";
import About from 'views/About'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "assets/css/bizor.css"
import "assets/scss/index.scss?v=1.5.0";
import "assets/css/demo.css?v=1.5.0";
import "assets/css/nucleo-icons-page-styles.css?v=1.5.0";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<BizorS />} />
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Products/BizorS" element={<BizorS />} />
        <Route path="/Products/BizorS/Pricing" element={<PricingBizorS />} />
        {/* <Route path="/Pricing" render={(props) => <Pricing {...props} />} />
        <Route path="/About" render={(props) => <About {...props} />} />
        <Route path="/Terms" render={(props) => <Terms {...props} />} />
        <Route path="/api-docs/v1" render={(props) => <RedocStandalone specUrl={apiurl}/>} /> */}
        
        {/* 
        <Route path="/api-docs/v1" render={(props) => <RedocStandalone specUrl={'https://bizor-docs.s3.us-east-2.amazonaws.com/apidef.json'}/>} /> */}
{/*          <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route
          path="/login-page"
          render={(props) => <LoginPage {...props} />}
        /> */}
        {/* <Route from="/" to="/Home" /> */}
      </Routes>
    </BrowserRouter>
)