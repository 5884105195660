import React, { useState } from "react";
import content from 'assets/contents/content-es';
import RequestDemoButton from 'components/RequestDemoButton'
import StartButton from 'components/StartButton'
import mlLogo from 'assets/img/mercadolibre.png'

// reactstrap components
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";

// core components

export default function Products() {

  const [openDemo,setOpenDemo] = useState(false)
  const toggleDemo = () => setOpenDemo(!openDemo)

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (pageHeader && window.innerWidth > 991) {
      const updateScroll = () => {
        const offsetTop = window.document.getElementById('products-block-1')?.offsetTop
        
        if(window.scrollY < offsetTop - 600) {
          let windowScrollTop = window.scrollY / 3;
          pageHeader.current.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
        }
      }
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
    <div className="d-none d-md-block" style={{minHeight: 200}}></div>
    {/* BLOCK 1 */}
    <Container>
      <Row className="text-center">
        <Col sm='12' md="12" lg="7">
          <div ref={pageHeader}>
            <img
              alt="bizor-s"
              src={require("assets/img/bizor-s-product-1.png")}
              className="parallax-product-image"
            />
          </div>
        </Col>
        <Col sm='12' md="12" lg="5">
          <div >
            <h5 className="text-start text-uppercase" style={{color: '#bbb'}}><i><b>bizor S</b></i></h5>
            <h2 className="text-start"><strong>{content.block0.title}</strong></h2>
            <p className="fs-5 text-start">{content.block0.description}</p>
            <button className='action-button-bg-white border rounded mt-5' onClick={toggleDemo}>Ver Demo</button>
          </div>
        </Col>
      </Row>
    </Container>
    <br /><br /><br /><br />
    {/* BLOCK AUTOGESTION */}
    <Container className="products-block-1">
      <Row className="text-bg-primary rounded-start">
        <Col lg="6" className="order-2 order-md-1">
          <div className=" p-5 rounded" id='products-block-1'>
            <h5 className="text-start text-uppercase" style={{color: '#bbb'}}>{content.block1.miniDesc}</h5>
            <h2 className="text-start"><strong>{content.block1.title}</strong></h2>
            <p className="fs-5 text-start">{content.block1.description}</p>
            <div className="header-block-actions mt-4">
              <RequestDemoButton style={{marginRight: 40}} />
              <StartButton />
            </div>
          </div>
        </Col>
        <Col lg="6" className="order-1 order-md-2 p-5">
          <video
            controls
            poster="https://bizor-docs.s3.us-east-2.amazonaws.com/bizor_demos/AutogestionPocosPasosPoster.png"
            className="grid-video"
            >
            <source src="https://bizor-docs.s3.us-east-2.amazonaws.com/bizor_demos/AutogestionPocosPasos.mp4" type="video/mp4" />
          </video>
        </Col>
      </Row>
    </Container>
    <br /><br /><br /><br />
    {/* BLOCK MERCADOLIBRE */}
    <Container>
      <Row className="text-center">
        <Col sm='12' md="12" lg="6">
          <div className='d-flex justify-content-center align-items-center m-auto h-100'>
            <img
              alt="ml-logo"
              className="img-fluid"
              src={mlLogo}
              />
          </div>
        </Col>
        <Col sm='12' md="12" lg="6">
          <div >
            <h5 className="text-start text-uppercase" style={{color: '#bbb'}}><i><b>bizor S</b></i></h5>
            <h2 className="text-start"><strong>{content.block2.title}</strong></h2>
            <p className="fs-5 text-start">{content.block2.description}</p>
            {/* <button className='action-button-bg-white border rounded mt-5' onClick={toggleDemo}>Ver Demo</button> */}
          </div>
        </Col>
      </Row>
    </Container>
    <Modal isOpen={openDemo} toggle={toggleDemo} size="xl" style={{padding: 16}}>
      <ModalHeader toggle={toggleDemo}>Factura en Pocos Pasos</ModalHeader>
      <ModalBody >
        <div className="youtube-container">
          <iframe
            className="youtube-responsive-iframe"
            src="https://www.youtube.com/embed/15B2d5fGEY4?si=Ly7NnqKc_sU-OnnO"
            title="Demo Bizor Pocos Pasos"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            />
        </div>
        
      </ModalBody>
    </Modal>
    </>
  );
}