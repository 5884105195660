import React from "react";

// reactstrap components
import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardText } from "reactstrap";
import { Link } from "react-router-dom";

// core components
import content from 'assets/contents/content-es'

export default function Testimonials() {

  return (
    <div className="text-center text-bg-secondary" style={{ padding: '80px 0'}}>
{/*       <h5 className="text-start text-uppercase" style={{color: '#999'}}>
        Caso de Éxito
      </h5> */}
      <h2 className="pb-5"><i>"Abrimos el camino hacia la transformación digital de tu empresa..."</i></h2>
      <Container>
        <Row md='2' className="justify-content-center">
          {content.testimonials.map((t,k) => (
            <Col>
              <Card className="p-5">
                
                  {
                    t.avatar &&
                    <div className="w-50 pb-5 m-auto">
                      <img src={t.avatar} class="img-fluid rounded" alt={`logo-${t.company}`}></img>
                    </div>
                  }
                
                <CardBody>
                  
                  <CardText>{Array.isArray(t.content) ? t.content.map(c => <p className="text-start fw-light fst-italic fs-6">{c}</p>) : t.content}</CardText>
                  <CardTitle tag="h5">{t.user}</CardTitle>
                  <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h6"
                  >
                    {t.role}, {t.company}
                  </CardSubtitle>
                  {t.webpage && <Link href={t.webpage}>{t.webpage}</Link>}
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}