import React from 'react'
import { Link } from "react-router-dom";

export default function CenteredGrid() {

  return (
    <div id='section-one' className="text-bg-dark">
        <div className='container p-5'>
            <div className='row'>
                <div className='col col-sm-6'>
                    <div style={{ width: '100%' }}>
                        <div>
                            <h3>Bizor Technologies, Inc.</h3>
                        </div>
                        <div className='mb-2'>
                            <div className="">Inteligencia de negocios en tiempo real, 24/7.</div>
                            <div className="">Optimización de operaciones a través de herramientas limpias e intuitivas.</div>
                            <div className="">Soluciones digitales integradas.</div>
                        </div>
                        <div className='d-flex justify-content-between mt-4' style={{maxWidth: 360}}>
                            <span style={{fontWeight: 200}}>© {new Date().getFullYear()} Bizor Technologies, Inc.</span>
                            <Link to="/Terms">Legal</Link>
                            <Link target='_blank' to="https://bizor.atlassian.net/servicedesk/customer/portal/1">Soporte</Link>
                        </div>
                    </div>
                </div>
                <div className='col col-sm-6 d-flex align-items-end flex-column'>
                    <div className='d-flex justify-content-between text-white' style={{minWidth: 220}}>
                        {/* FACEBOOK */}
                        <Link className='text-white fs-4' target="_blank" to='https://www.facebook.com/bizor.technologies'>
                            <i className="bi bi-facebook"></i>
                        </Link>
                        {/* LINKEDIN */}
                        <Link className='text-white fs-4' target="_blank" to='https://www.linkedin.com/company/bizor-io'>
                            <i className="bi bi-linkedin"></i>
                        </Link>
                        {/* TWITTER X */}
                        <Link className='text-white fs-4' target="_blank" to='#'>
                            <i className="bi bi-twitter-x"></i>
                        </Link>
                        {/* YOUTUBE */}
                        <Link className='text-white fs-4' target="_blank" to='https://youtube.com/@BizorSphere?si=BGWGd9Kb_s3xT6ZU'>
                            <i className="bi bi-youtube"></i>
                        </Link>
                        {/* INSTAGRAM */}
                        <Link className='text-white fs-4' target="_blank" to='https://www.instagram.com/bizor.io'>
                            <i className="bi bi-instagram"></i>
                        </Link>
                    </div>
                    <div className="d-flex justify-content-end" >
                            
                        </div>
                        <div className="d-flex justify-content-end">
                            <p>
                                Contacto
                                {/* <Link to="/About">Equipo</Link>
                                <Link to="https://bizor.atlassian.net/servicedesk/customer/portal/1">Soporte</Link> */}
                            </p>
                        </div>
                        <div className="d-flex justify-content-end">
                            <p>
                                <Link to="#">info@bizor.io</Link>
                            </p>
                        </div>
                    </div>
            </div>
        </div>
    </div>
  );
}



