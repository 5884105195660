/*eslint-disable*/
import React from "react"
import content from 'assets/contents/history-es'
import { Container, Row, Col } from "reactstrap";
// core components

function Header(props) {

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
  <div className="page-header">
        <div ref={pageHeader}>
          <div id="background-video">
            <video autoPlay loop muted>
              <source src={require("assets/videos/VideoBanner.mp4")} type="video/mp4" />
            </video>
            <div className="clear-filter vw-100 vh-100 position-absolute top-0" filter-color="blue"></div>
          </div>
        </div>
        <Container>
          <div className="text-bg-light p-5 rounded-end" style={{marginTop: '40vh', width: '85%',zIndex:1000, overflow: 'auto'}} >
            <h1 className="text-start"><strong>{content.about.title} <span style={{fontFamily: 'Amaranth'}}>bizor</span></strong></h1>
            { 
              content.about.content.map((p,key) => {
                //let htmlFragment = document.createRange().createContextualFragment(p);
                //const Temp = document.createElement('span');
                //Temp.innerHTML = p
                const newNode = document.createElement('span'); 
                newNode.innerHTML = p
                return <h3 className="text-start" style={{fontWeight: 400}} key={key} ref={(nodeElement) => {nodeElement && nodeElement.appendChild(newNode)}} />
                /* return <Typography key={key} component='h4' className={classes.claim}>{Temp}</Typography> */
              })
            }
          </div>
        </Container>
        <Container>
          <Row>
            <Col sm="6" className="pt-5 pb-5">
              <div className="text-bg-primary p-5 rounded">
                <h2 className="text-start"><strong>Simplificamos la integración de las soluciones en la nube que tu empresa necesita.</strong></h2>
                <h4 className="text-start">La tecnología no tiene porque ser complicada. Nuestras soluciones hacen que adoptar la nube sea sencillo, permitiéndote desbloquear un mundo de oportunidades y mantenerte al día en un mundo donde la transformación digital es un deber.</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


{/*       <Box className={classes.session1}>
        <Box className={classes.content} >
          <Typography className={classes.miniDesc}>{content.blockHeader.miniDesc}</Typography>
          <Typography component='h1' className={classes.mainTitle}>{content.blockHeader.claim}</Typography>
          <Typography component='h3' className={classes.claim}>{content.blockHeader.description}</Typography>
          <Box display='flex' justifyContent='space-around' mt={10}>
            <Box>
              <StartButton outlined />
            </Box>
            <Box width='45%' display={{xs: "none", sm: 'block'}} >
              <RequestDemoButton  />
            </Box>
          </Box>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center' flex={3}>
          <Parallax  y={[-30, 30]} tagOuter="figure">
            <iframe className={classes.demoVideo} src="https://www.youtube.com/embed/15B2d5fGEY4?si=Ly7NnqKc_sU-OnnO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </Parallax>
        </Box>
      </Box>
      <Box display={{xs: 'flex', sm: 'none'}} flexDirection='column' justifyContent='space-around' alignItems='center' mt={10}>
        <img
          alt="bzr-demo-collage"
          className={classes.demoCollage}
          src={bzrCollage}
        ></img>
        <Box display='flex' justifyContent='space-around' mt={10}>
          <RequestDemoButton  />
        </Box>
      </Box> */}
    </>
  );
}

export default Header;