import React from 'react'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import Banner from './Banner'
/*import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import SectionThree from './SectionThree'
import SectionIntegrations from './SectionIntegrations' */
import RequestDemoButton from 'components/RequestDemoButton'


const Home = (props) => {


  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return(
      <>
        <Navbar />
        <div className="wrapper">
          <Banner />
          
          <div className="d-flex justify-content-center m-5 p-5">
            <RequestDemoButton border={true} />
          </div>
          <Footer />
        {/*  <Fade in={show}>
            <Button className="btn-icon btn-round" color="info" type="button" onClick={handleBackToTop}>
              <i className="now-ui-icons ui-2_favourite-28"></i>
            </Button>
          </Fade> */}
        </div>
      </>
  )
}

export default Home