import React from 'react'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import Banner from './Banner'
import Products from './Products'
import Testimonials from './Testimonials'
import Specs from './Specs'
/*import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import SectionThree from './SectionThree' */
import Integrations from './Integrations'
import RequestDemoButton from 'components/RequestDemoButton'
import StartButton from 'components/StartButton'
import { Fade, Button } from 'reactstrap'

const Home = (props) => {


  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return(
      <>
        <Navbar />
        <div className="wrapper">
          <Banner />
          <Products />
          <div className="main">
            <div id="back-to-top-anchor"></div>
            
            <Specs />
            <Integrations />
            <Testimonials />
          </div>
{/*          <SectionOne />
          <SectionTwo />
          <SectionIntegrations />
          <SectionThree /> */}
          <div className="d-flex justify-content-center text-bg-secondary" style={{paddingBottom: 100}}>
            <RequestDemoButton border={true} style={{marginRight: 80}} />
            <StartButton />
          </div>
          <Footer />
        </div>
      </>
  )
}

export default Home