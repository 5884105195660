import React from 'react'
import { Button, Modal } from 'reactstrap'
//import Subscriptions from '../../views/Pricing/Subscriptions'
import content from 'assets/contents/content-es'

export default function StartButton(props) {
  
  const [state, setState] = React.useState({open: false})
  const toggleOpen = () => setState(prev => ({...prev, open: !prev.open}))

  return(
    <>
      <Button href="/Products/BizorS/Pricing" className={props.className || 'action-button-filled'} >
        {content.general.startButton}
      </Button>
      <Modal isOpen={state.open} toggle={toggleOpen}>
        <div>test</div>
        {/* <Subscriptions /> */}
      </Modal>
    </>
  )
}