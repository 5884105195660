import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';


import content from 'assets/contents/content-es'

const items = [
  {
    src: require('assets/img/services/migration.png'),
    altText: 'Slide 1',
  },
  {
    src: require('assets/img/services/app.png'),
    altText: 'Slide 2',
  },
  {
    src: require('assets/img/services/bigdata.png'),
    altText: 'Slide 3',
  },
  {
    src: require('assets/img/services/machinelearning.png'),
    altText: 'Slide 1',
  },
  {
    src: require('assets/img/services/security.png'),
    altText: 'Slide 2',
  },
  {
    src: require('assets/img/services/iot.png'),
    altText: 'Slide 3',
  },
  {
    src: require('assets/img/services/devops.png'),
    altText: 'Slide 1',
  },
  {
    src: require('assets/img/services/microservices.png'),
    altText: 'Slide 2',
  }
];

function Example(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item,k) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div className='w-100'>
          <img src={item.src} alt={item.altText} style={{objectFit: 'cover'}} />
        </div>
        <CarouselCaption
          captionHeader={<h3>{content.services[k].caption}</h3>}
          captionText={<h5>{content.services[k].description}</h5>}
          className='bg-black p-5 opacity-75 rounded'
        />
        <div className='d-md-none text-center p-3 text-bg-dark' style={{height: 200}}>
          <h3>{content.services[k].caption}</h3>
          <h5>{content.services[k].description}</h5>
          <br />
        </div>
      </CarouselItem>
    );
  });

  return (
    <div>
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
    </div>
  );
}

export default Example;